import React from 'react'

// App components
import ContentAndSignupForm from '../../../components/ContentAndSignupForm'

// App content
import tenThingsCover from '../../../assets/10Things3DCover.png'

class Page extends React.Component {
  render() {
    const content = (
      <div>
        <p css={{ marginBottom: 0 }}>
          Are you a Looking for a Family Friendly Orthodontist?
        </p>
        <h2>
          Top 10 Things you Must Know Before Choosing Your Family’s Orthodontist
        </h2>
        <p>
          Choosing an orthodontist for you family can be stressful. You want to
          ensure a great fit across many vectors: Experience, Cost, Kindness,
          etc. How are you supposed to make this choice? We’ve come up with the
          top 10 things you must know before choosing your family’s
          orthodontist. We’d love to share them with you!
        </p>
      </div>
    )
    return (
      <div>
        <ContentAndSignupForm
          thumbnail={tenThingsCover}
          title={
            "Top 10 Things You Need to Know Before Choosing Your Family's Orthodontist"
          }
          content={content}
          configKey="tenThings"
          captchaId="recaptcha_c612f05dbec6e61322cafca5186b5b70"
        />
      </div>
    )
  }
}

export default Page
