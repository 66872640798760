import React from 'react'

// App components
import InfusionsoftForm from '../components/InfusionsoftForm'
import Layout from '../components/layout'

// App content
import arrow from '../assets/arrow.png'

// App utils
import { getRecaptchaId } from '../utils/formsConfig'
import { rhythm } from '../utils/typography'
import { themeStyles, colors, metrics, presets } from '../utils/theme'

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.lightGrey}`,
    padding: rhythm(1),
    width: '100%',
    maxWidth: '500px',
    ...themeStyles.shadow,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  input: {
    fontFamily: 'inherit',
    fontSize: '100%',
    lineHeight: '1.15',
    margin: '0',
    overflow: 'visible',
    padding: '.5rem',
    backgroundColor: '#fff',
    border: '1px solid #a8a8a8',
    boxSsizing: 'border-box',
    transition: 'border-color .2s cubic-bezier(.175,.885,.32,1.276)',
    borderRadius: '2px',
    minWidth: '200px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    marginTop: metrics.defaultMargin,
  },
  row: {
    padding: rhythm(1 / 2),
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },
  thumbnail: {
    maxWidth: '150px',
    objectFit: 'contain',
    [presets.Tablet]: {
      maxWidth: '300px',
    },
  },
  required: {
    content: '*',
    color: '#e4002b',
    marginLeft: 'clamp(.69rem,calc(.66rem + .12vw),.75rem)',
    fontWeight: '600',
  },
  label: {
    fontSize: rhythm(3 / 4),
    color: '#414141',
    fontWeight: '600',
    marginTop: '10px',
  },
}

const FreeReportItem = ({
  title,
  subtitle,
  thumbnail,
  content,
  postContent,
  mainTitle,
  configKey,
  style = {},
}) => {
  return (
    <div id={'content-and-signup-form' + configKey} css={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          flex: 1,
          color: 'black',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: colors.lightGrey,
        }}
      >
        {mainTitle && (
          <h1
            css={{
              fontWeight: 100,
              textAlign: 'center',
              color: colors.brightBlue,
            }}
          >
            {mainTitle}
          </h1>
        )}
        <h2 css={{ color: 'black', fontWeight: 100, textAlign: 'center' }}>
          {title}
        </h2>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            position: 'relative',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <div css={{ height: '100%', alignItems: 'center' }}>
            <img src={thumbnail} css={styles.thumbnail} />
          </div>

          <InfusionsoftForm
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            configKey={configKey}
          >
            <div css={{ width: '100%' }}>
              <img
                src={arrow}
                css={{
                  [presets.Tablet]: { maxWidth: '80px', display: 'block' },
                  [presets.Phablet]: { display: 'block' },
                  transform: 'rotate(-60deg)',
                  display: 'none',
                  maxWidth: '40px',
                }}
              />
            </div>
            <div css={styles.inputContainer}>
            <label css={styles.label} className="required">
              First Name
            </label>
            <input
              css={styles.input}
              id="inf_field_FirstName"
              name="inf_field_FirstName"
              placeholder="First Name"
              type="text"
              required
            />
            </div>
            <div css={styles.inputContainer}>

            <label css={styles.label} className="required">
              Email
            </label>
            <input
              css={styles.input}
              id="inf_field_Email"
              name="inf_field_Email"
              placeholder="Email"
              type="text"
              required
            />
            </div>
            <div css={{ marginTop: rhythm(1) }}>
              <button
                css={{
                  flex: 0,
                  width: '260px',
                  padding: '10px',
                  border: `2px solid ${colors.brightBlue}`,
                  backgroundColor: colors.brightBlue,
                  color: '#fff',
                  cursor: 'pointer',
                }}
                className="infusion-recaptcha"
                id={getRecaptchaId(configKey)}
                type="submit"
              >
                Claim Your FREE Report
              </button>
            </div>
          </InfusionsoftForm>
        </div>
      </div>
      <div
        css={{
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...themeStyles.innerTextBreak,
          textAlign: 'center',
          ...themeStyles.elementMargin,
        }}
      >
        {content}
      </div>
      {postContent}
    </div>
  )
}

class ContentAndSignupForm extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <FreeReportItem
          mainTitle={this.props.mainTitle}
          title={this.props.title}
          thumbnail={this.props.thumbnail}
          content={this.props.content}
          postContent={this.props.postContent}
          configKey={this.props.configKey}
        />
      </Layout>
    )
  }
}

export default ContentAndSignupForm
